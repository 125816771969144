$font-size-base: 0.95rem;
@import 'bootstrap/bootstrap';
@import 'native.css';

div.post-date {
	@extend .text-muted;
	margin-bottom:2em;
}

div.post-content img {
	@extend .img-fluid;
	margin-right:auto;
	margin-left:auto;
	display:block;
}

div.post-content hr {
	margin:2em 0;
}

div.post-content iframe,
div.post-content video {
	display:block;
	margin-right:auto;
	margin-left:auto;
	margin-bottom:1em;
	max-width:100%;
}

div.post-content table {
	@extend .table;
}

div.post-content blockquote {
	@extend .blockquote;
	padding:0.5em 1.5em;
	border-left:6px solid $red-200;
	font-style:italic;
}

div.post-content h1, div.post-content h2, div.post-content h3, div.post-content h4, div.post-content h5 {
	margin-top:2rem;
}

figure.highlight { 
	margin: 0px; 
}

figure.highlight > pre { 
	padding: 1em; 
	line-height: 1.5em; 
	white-space: pre-wrap; 
	word-wrap: normal; 
	font-size: 0.9em; 
	background-color: hsl(0, 0%, 25%); 
	color: hsl(0, 0%, 96%);
}
